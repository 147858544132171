import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import api from "./../../../../api";
import { withRouter } from "react-router-dom";
import { Button } from "./../../../../components/share/InsightechUI";
import Pagination from "../../../../components/app/Pagination";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { UserDataContext } from "./../../../../components/app/UserData";
import { SegmentsContext } from "./../../../../context/Segments";
import Table from "./../../../../components/share/Table";
import {
  captureExceptionToSentry,
  ellipsize,
  kSeparatorDecimal,
  navigateToPlanUpgrade,
  parseUrl,
} from "./../../../../utils";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";
import { ERRORS_MAPPED, DEFAULT_ERROR } from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";
import { AiOutlineCopy } from "react-icons/ai";
import Notification from "../../../../components/share/InsightUI/Notification";
import ExtensionModal from "../Pages/components/ExtensionModal";
import UpgradeFlag from "../../../../components/app/FilterPanel/components/UpgradeFlag";
import { MdOutlineExtension } from "react-icons/md";

function Forms(props) {
  const { location } = props;
  const profileId = props.match.params.profileId;
  const { authUser } = useContext(AuthContext);
  const {
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
    featureFlags,
  } = useContext(UserDataContext);
  const { selectedSegments, setSelectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [queryId, setQueryId] = useState(null);
  const [key, setKey] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isTotalLoaded, setIsTotalLoaded] = useState(false);
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [analysisOptions, setAnalysisOptions] = useState({
    url: "",
    segment: "",
    title: false,
  });

  // Live clickmap pop up
  const [clickedRowPageUrl, setClickedRowPageUrl] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupIndex, setPopupIndex] = useState();

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const [isCopied, setIsCopied] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const searchInputRef = useRef(null);

  const handleOpenPopup = (e) => {
    const rowIndex = parseInt(e.currentTarget.dataset["index"]);
    const url = JSON.parse(e.currentTarget.dataset["payload"]).url;

    setIsPopupVisible(true);
    setPopupIndex(rowIndex);
    setClickedRowPageUrl(url);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setPopupIndex(null);
    setClickedRowPageUrl(null);
  };

  const handleClickView = (e) => {
    const data = JSON.parse(e.currentTarget.dataset["payload"]);
    const [visitorId, sessionNumber] = data.exampleSessionId.split(":");
    props.history.push(
      `/profile/${profileId}/replay/session/${visitorId}/${sessionNumber}`,
      {
        replayOptions: {
          activeTab: "analytics",
          analyticsMode: "formAnalysis-lastClicks",
          url: data.url,
        },
      }
    );
  };

  const AnalyticsActionButton = ({
    className,
    onClick,
    tooltipContent,
    icon,
    ...rest
  }) => (
    <div
      className={`analytics-action ${className}`}
      onClick={onClick}
      data-tooltip-id="form-tooltip"
      data-tooltip-content={tooltipContent}
      {...rest}
    >
      {icon}
    </div>
  );

  const columns = useMemo(() => {
    let c = [
      {
        Header: "View Analytics",
        accessor: "exampleSessionId",
        Cell: (param) => {
          const { row } = param;
          const payload = JSON.stringify(row.original);

          // Shared "outlined" button for analyzing the live page
          const LivePageButton = (
            <AnalyticsActionButton
              className="solid"
              onClick={handleOpenPopup}
              tooltipContent="Analyse the live page with Insightech Chrome Extension"
              icon={<MdOutlineExtension size={18} color="white" />}
              data-payload={payload}
              data-index={row.index}
            />
          );

          return (
            <div className="action-buttons">
              {row.original.exampleSessionId ? (
                <>
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleClickView}
                    data-tooltip-id="form-tooltip"
                    data-tooltip-content="Analyse the page"
                    data-payload={payload}
                  >
                    Analyze
                  </Button>
                  {LivePageButton}
                </>
              ) : (
                row.index !== 0 && (
                  <>
                    <div className="position-relative">
                      <Button
                        color={
                          !!featureFlags.isLiteTrackingFlag
                            ? "outlined-primary"
                            : "disabled"
                        }
                        size="small"
                        data-tooltip-id="form-tooltip"
                        data-tooltip-content={
                          !!featureFlags.isLiteTrackingFlag
                            ? "Upgrade your plan to unlock all insights"
                            : "No replay available. Use the Chrome Extension to analyze on live page ."
                        }
                        data-payload={payload}
                        {...(!!featureFlags.isLiteTrackingFlag && {
                          onClick: () => navigateToPlanUpgrade(),
                        })}
                      >
                        Analyze
                      </Button>
                      {!!featureFlags.isLiteTrackingFlag && (
                        <div
                          className="position-absolute"
                          style={{
                            top: -6,
                            right: -6,
                          }}
                        >
                          <UpgradeFlag isIconOnly={true} id={"form-analysis"} />
                        </div>
                      )}
                    </div>
                    {LivePageButton}
                  </>
                )
              )}
            </div>
          );
        },
      },
      {
        Header: "Page URL",
        accessor: "url",
        Cell: (param) => {
          const { value } = param;
          if (value) {
            const formattedValue = parseUrl(value);
            return (
              <span className="ml-2 d-flex justify-content-between align-items-center">
                <span
                  className="flex-grow-1"
                  data-tooltip-id="form-tooltip"
                  data-tooltip-content={value}
                >
                  {ellipsize(
                    formattedValue
                      ? `${formattedValue.pathname}${formattedValue.search}${formattedValue.hash}`
                      : value,
                    50
                  )}
                </span>
                <span
                  className="url-copy-button"
                  onClick={() => handleCopyUrl(param.row.original.url)}
                  data-tooltip-id="form-tooltip"
                  data-tooltip-content="Copy page url"
                >
                  <AiOutlineCopy size={14} />
                </span>
              </span>
            );
          } else {
            return (
              <span className="ml-2 url-span">
                <strong>(Grand Total)</strong>
              </span>
            );
          }
        },
      },
      {
        Header: <div className="pageHeader">Page Views</div>,
        accessor: "pageviews",
        Cell: ({ value }) => {
          return kSeparatorDecimal(value);
        },
      },
      {
        Header: <div className="pageHeader">Form Bounce Rate</div>,
        accessor: "formBounceRate",
        Cell: ({ value }) => {
          return `${Math.round(value * 100)}%`;
        },
      },
      {
        Header: <div className="pageHeader">Median Interacted Fields</div>,
        accessor: "medianClickFieldCount",
        Cell: ({ value }) => {
          return `${Math.round(value)}`;
        },
      },
      {
        Header: <div className="pageHeader"># of Fields</div>,
        accessor: "maxClickFieldCount",
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        Header: <div className="pageHeader">Median Duration</div>,
        accessor: "medianDuration",
        Cell: ({ value }) => {
          return moment
            .duration(value, "milliseconds")
            .format("HH:mm:ss", { trim: false });
        },
      },
    ];
    if (analysisOptions.title) {
      c.splice(2, 0, {
        Header: "Page Title",
        accessor: "title",
      });
    }
    return c;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisOptions, page]);

  const handleApiError = (error, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
    } else {
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readFormList = ({ queryId, key, page, pageSize }) => {
    api.cancel("readFormList");
    api
      .readFormList(authUser, {
        profileId,
        queryId,
        key,
        page,
        pageSize,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readFormList({ queryId, key, page, pageSize });
        }
        if (res.data.status === 200) {
          setIsLoadingData(false);
          setIsDataLoaded(true);
          setTotalRows(res.data.data.totalRows);
          setData(res.data.data.records || []);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const readFormTotal = ({ queryId, key }) => {
    api.cancel("readFormTotal");
    api
      .readFormTotal(authUser, {
        profileId,
        queryId,
        key,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readFormTotal({ queryId, key });
        }
        if (res.data.status === 200) {
          setIsLoadingTotal(false);
          setIsTotalLoaded(true);
          setTotal(res.data.data.records || []);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingTotal));
  };

  const fetchFormList = () => {
    setIsLoadingData(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryFormList");
    api.cancel("readFormList");
    api
      .queryFormList(authUser, {
        profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        filter: JSON.stringify(appliedFilters),
        pageURL: JSON.stringify({
          operator: "contains",
          values: [analysisOptions.url],
        }),
        title: analysisOptions.title,
        segments: JSON.stringify(segmentsArray),
        sort: "pageviews-desc",
      })
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readFormList({ queryId, key, page, pageSize });
          setQueryId(queryId);
          setKey(key);
          setAlert({
            show: false,
            type: "",
            message: "",
            count: 0,
          });
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const fetchFormTotal = () => {
    setIsLoadingTotal(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryFormTotal");
    api.cancel("readFormTotal");
    api
      .queryFormTotal(authUser, {
        profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        filter: JSON.stringify(appliedFilters),
        pageURL: JSON.stringify({
          operator: "contains",
          values: [analysisOptions.url],
        }),
        segments: JSON.stringify(segmentsArray),
        sort: "pageviews-desc",
        title: analysisOptions.title,
      })
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readFormTotal({ queryId, key });
        } else {
          setIsLoadingTotal(false);
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingTotal));
  };

  // Click the "search" button to search the page URL
  const handleSearchUrl = () => {
    const value = searchInputRef.current.value;
    setAnalysisOptions({
      ...analysisOptions,
      url: value,
    });
  };

  // Pressing the "Enter" key on the search input
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchUrl();
    }
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setAnalysisOptions({
      ...analysisOptions,
      title: value,
    });
  };

  const handleCopyUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopyMessage("Successfully copied page url!");
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      setIsCopied(false);
    }
  };

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      api.cancel("queryFormTotal");
      api.cancel("readFormTotal");
      api.cancel("queryFormList");
      api.cancel("readFormTotal");
    };
  }, []);

  // Pagination
  useEffect(() => {
    if (queryId && key) {
      setIsLoadingData(true);
      readFormList({ queryId, key, page, pageSize });
      // Grand Total is the same on all pages
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    if (
      !isApiServerLoading &&
      profileId &&
      appliedFilters &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      !isDependenciesLoaded
    ) {
      setIsDependenciesLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    isDependenciesLoaded,
    isApiServerLoading,
  ]);

  useEffect(() => {
    if (isDependenciesLoaded && profileId) {
      fetchFormList();
      fetchFormTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    appliedFilters,
    analysisOptions,
    selectedSegments,
    startDate,
    endDate,
    isDependenciesLoaded,
  ]);

  useEffect(() => {
    if (location.state && location.state.analysisOptions) {
      setAnalysisOptions(location.state.analysisOptions);
      if (location.state.analysisOptions.segment) {
        setSelectedSegments({
          ...selectedSegments,
          [location.state.analysisOptions.segment]: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Alert show={alert.show} type={alert.type} message={alert.message} />
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card shadow">
            <div className="card-body">
              <div className="search-url form-inline">
                <div className="search-area">
                  <input
                    defaultValue={analysisOptions.url}
                    ref={searchInputRef} // Attach ref to the input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search for page URL"
                    onKeyDown={handleKeyDown} // Add keydown event handler
                  />
                  <div className="search-button">
                    <Button
                      size="flag"
                      color="aluminum"
                      onClick={handleSearchUrl}
                    >
                      search
                    </Button>
                  </div>
                </div>
                <select
                  className="form-control form-control-sm"
                  onChange={handleChangeTitle}
                >
                  <option value={false}>Show page URL only</option>
                  <option value={true}>Show page URL and title</option>
                </select>
              </div>
              <Table
                hScroll
                data={total.concat(data)}
                columns={columns}
                isLoading={isLoadingData || isLoadingTotal}
                isDataLoaded={isDataLoaded && isTotalLoaded}
                loadingText={
                  <>
                    <i className="fa fa-spinner spin"></i> Loading Forms...
                  </>
                }
              />
              {!isLoadingData && (
                <Pagination
                  activePage={page + 1}
                  totalRows={totalRows}
                  pageSize={pageSize}
                  changeHandler={(pageNum, pageSize) => {
                    setPage(pageNum - 1);
                    setPageSize(pageSize);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isCopied && (
        <Notification
          message={copyMessage}
          onClose={() => setIsCopied(false)}
        />
      )}
      {isPopupVisible && popupIndex && (
        <ExtensionModal
          isPopupVisible={isPopupVisible}
          handleClosePopup={handleClosePopup}
          clickedRowPageUrl={clickedRowPageUrl}
        />
      )}
      <ReactTooltip id="form-tooltip" className="tooltip-lg" />
    </>
  );
}

Forms.propTypes = {};
Forms.defaultProps = {};

export default withRouter(Forms);
