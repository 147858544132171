import InsightechIcon from "../../share/InsightechIcon";
import { ImEnter, ImExit } from "react-icons/im";
import { IoSettingsOutline } from "react-icons/io5";
import {
  MdOutlineGroups,
  MdDevices,
  MdOutlineCampaign,
  MdOutlineAdsClick,
} from "react-icons/md";
import {
  FaCode,
  FaRegMap,
  FaRegClock,
  FaChrome,
  FaRegAngry,
} from "react-icons/fa";
import {
  LuGoal,
  LuFileClock,
  LuIdCard,
  LuMousePointerClick,
  LuTextCursorInput,
  LuDatabase,
  LuSettings2,
} from "react-icons/lu";
import { RiPagesLine, RiFocusMode } from "react-icons/ri";
import { LiaUserTagSolid } from "react-icons/lia";
import {
  PiCity,
  PiWindowsLogoBold,
  PiMouseScroll,
  PiDotsThreeDuotone,
  PiTextbox,
} from "react-icons/pi";
import { GoServer } from "react-icons/go";
import {
  VscVersions,
  VscSourceControl,
  VscBracketError,
} from "react-icons/vsc";
import { RxWidth, RxHeight } from "react-icons/rx";
import {
  TbArrowAutofitWidth,
  TbArrowAutofitHeight,
  TbInputCheck,
  TbPointerMinus,
} from "react-icons/tb";
import { SiGoogledataproc } from "react-icons/si";
import { BsLink } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { BiSolidBookContent } from "react-icons/bi";

// Nav menu icons
export const Dashboard = () => <InsightechIcon name="Dashboard" />;
export const PageAnalysis = () => <InsightechIcon name="Pages" />;
export const LandingPages = () => <ImEnter />;
export const FormAnalysis = () => <InsightechIcon name="Forms" />;
export const Funnels = () => <InsightechIcon name="Funnels" />;
export const Pathing = () => <InsightechIcon name="Pathing" />;
export const Replays = () => <InsightechIcon name="Replays" />;
export const Notes = () => <InsightechIcon name="Notes" />;
export const Segments = () => <InsightechIcon name="Segments" />;
export const Reports = () => <InsightechIcon name="Reports" />;
export const Settings = () => <InsightechIcon name="Settings" />;
export const SettingsProfile = () => <IoSettingsOutline />;
export const SettingsUsers = () => <MdOutlineGroups />;
export const SettingsCode = () => <FaCode />;
export const SettingsTracking = () => <LuSettings2 />;
export const SettingsConversions = () => <LuGoal />;

// Add other icons as needed

// Filters menu icons
// Session Metrics
export const SessionDuration = () => <FaRegClock />;
export const TotalSessions = () => <LuFileClock />;
export const TotalPageviews = () => <RiPagesLine />;
export const SessionType = () => <LiaUserTagSolid />;
// User Identification
export const City = () => <PiCity />;
export const Region = () => <FaRegMap />;
export const Country = () => <FaRegMap />;
export const IPAddress = () => <GoServer />;
export const VisitorID = () => <LuIdCard />;
// Device Attributes
export const DeviceFamily = () => <MdDevices />;
export const Browser = () => <FaChrome />;
export const BrowserVersion = () => <VscVersions />;
export const OS = () => <PiWindowsLogoBold />;
export const OSVersion = () => <VscVersions />;
export const BrowserWidth = () => <RxWidth />;
export const BrowserHeight = () => <RxHeight />;
export const ScreenWidth = () => <TbArrowAutofitWidth />;
export const ScreenHeight = () => <TbArrowAutofitHeight />;
// Traffic/Referral Attributes
export const TrafficSource = () => <VscSourceControl />;
export const TrafficMedium = () => <SiGoogledataproc />;
export const TrafficCampaign = () => <MdOutlineCampaign />;
export const RefererHost = () => <BsLink />;

// Pages Visited / Content Loaded
export const VisitPage = () => <CgWebsite />;
export const EntryPage = () => <ImEnter />;
export const ExitPage = () => <ImExit />;
export const ContentLoad = () => <BiSolidBookContent />;
// On-page Engagement
export const Click = () => <LuMousePointerClick />;
export const LastClick = () => <TbPointerMinus />;
export const Hover = () => <MdOutlineAdsClick />;
export const Scroll = () => <PiMouseScroll />;
// Form / Field Events
export const LastFieldClick = () => <LuTextCursorInput />;
export const FieldChange = () => <PiTextbox />;
export const FieldFocus = () => <RiFocusMode />;
export const FieldBlur = () => <PiDotsThreeDuotone />;
export const FormSubmission = () => <TbInputCheck />;
// UX Issues / Frustration
export const RageClick = () => <FaRegAngry />;
// System / Technical Events
export const ApiError = () => <VscBracketError />;
// Integrations
export const CustomData = () => <LuDatabase />;
// Goals
export const Conversion = () => <LuGoal />;
